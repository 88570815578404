import React, { useRef, useEffect, useState } from 'react';
import { VStack, HStack, Box, Button } from '@chakra-ui/react';
import { createFFmpeg } from '@ffmpeg/ffmpeg';

const ffmpeg = createFFmpeg({
  log: true,
});

export default function SaveRecording ({ recordingData }) {
  const recordingVideoElement = useRef(null);
  const [ffmpegLoading, setFfmpegLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState({});
  const supportedFormats = ['mp4', 'avi'];

  useEffect(() => {
    if (recordingData) {
      const videoUrl = window.URL.createObjectURL(recordingData);
      recordingVideoElement.current.src = videoUrl;
    }
  }, [recordingData]);

  const convert = async format => {
    setFfmpegLoading(true);
    if (!ffmpeg.isLoaded()) {
      try {
        await ffmpeg.load();
      } catch (error) {
        console.error(error);
      }
    }
    try {
      const buffer = Buffer.from(await recordingData.arrayBuffer());
      ffmpeg.FS('writeFile', 'input.webm', new Uint8Array(buffer));
      const filename = `output.${format}`;
      await ffmpeg.run('-i', 'input.webm', filename);
      const data = ffmpeg.FS('readFile', 'output.mp4');
      var url = window.URL.createObjectURL(
        new Blob([data.buffer], { type: 'video/mp4' })
      );
      setDownloadReady({ ...downloadReady, [format]: url });
    } catch (error) {
      console.error(error);
    } finally {
      setFfmpegLoading(false);
    }
  };

  return (
    <VStack spacing={8}>
      <Box width='90vh'>
        <video
          ref={recordingVideoElement}
          width='auto'
          height='auto'
          controls
        ></video>
      </Box>
      <HStack align='left' width='90vh'>
        <Button colorScheme='teal'>Download webm</Button>
      </HStack>
      {supportedFormats.map(format => (
        <HStack align='left' width='90vh' key={'video-format' + format}>
          <Button
            colorScheme='teal'
            onClick={() => convert(format)}
            loadingText='Loading'
            isLoading={ffmpegLoading}
            disabled={downloadReady[format] || ffmpegLoading}
          >
            Convert to {format}
          </Button>
          <Button
            as='a'
            colorScheme='green'
            href={downloadReady[format]}
            disabled={!downloadReady[format]}
            download={`recording.${format}`}
          >
            Download
          </Button>
        </HStack>
      ))}
    </VStack>
  );
}
