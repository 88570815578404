import React, { useRef, useState } from 'react';
import {
  createStandaloneToast,
  Text,
  Box,
  Button,
  VStack,
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react';
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md"
import SaveRecording from './SaveRecording';

const getSupportedMimeType = () => {
  return [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp9.0',
    'video/webm;codecs=vp8',
    'video/webm;codecs=vp8.0',
    'video/webm;codecs=h264',
    'video/webm;codecs=H264',
    'video/webm;codecs=avc1',
    'video/webm;codecs=vp8,opus',
    'video/WEBM;codecs=VP8,OPUS',
    'video/webm;codecs=vp9,opus',
    'video/webm;codecs=vp8,vp9,opus',
    'video/webm;codecs=h264,opus',
    'video/webm;codecs=h264,vp9,opus',
    'video/x-matroska;codecs=avc1',
    'video/webm',
  ].find(m => MediaRecorder.isTypeSupported(m));
};

export default function Record () {
  const [askingForSource, setAskingForSource] = useState(false);
  const [streamSource, setStreamSource] = useState(null);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [saveVideoOpened, setSaveVideoOpened] = useState(null);
  const [recordingData, setRecordingData] = useState(null);

  const toast = createStandaloneToast();

  const previewVideo = useRef(null);

  var recordedChunks = [];

  const streamSourceInactivated = () => {
    setStreamSource(null);
  };

  const cancelSource = () => {
    if (streamSource != null) {
      streamSource.getTracks().forEach(track => track.stop());
      recordedChunks = [];
    }
  };

  const handleRecorderData = e => {
    recordedChunks.push(e.data);
  };

  const handleRecorderStop = () => {
    const blob = new Blob(recordedChunks);
    setRecordingData(blob);
    setSaveVideoOpened(true);
  };

  const askForSource = async () => {
    try {
      setAskingForSource(true);

      cancelSource();

      const source = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: { mediaSource: 'screen' },
      });
      source.oninactive = streamSourceInactivated;

      previewVideo.current.srcObject = source;
      previewVideo.current.play();

      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error('No supported mime type found for recording');
      }
      const options = { mimeType: mimeType };

      let newMediaRecorder = new MediaRecorder(source, options);
      newMediaRecorder.ondataavailable = handleRecorderData;
      newMediaRecorder.onstop = handleRecorderStop;

      setMediaRecorder(newMediaRecorder);
      setStreamSource(source);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      setAskingForSource(false);
    }
  };

  const startRecording = () => {
    setRecordingStarted(true);
    mediaRecorder.start();
  };

  const stopRecording = () => {
    setRecordingStarted(false);
    mediaRecorder.stop();
  };

  const onClose = () => {
    setSaveVideoOpened(false);
    cancelSource();
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={saveVideoOpened} size='xl'>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Save your recording</DrawerHeader>
            <DrawerBody>
              <SaveRecording recordingData={recordingData} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <VStack textAlign='center' justifyContent='center' spacing={8}>
        {streamSource == null && (
          <Box>
            <Text fontSize='3xl'>Free screen or application recording website.</Text>
            <List textAlign="left">
              <ListItem fontSize="2xl">
                <ListIcon as={MdRemoveCircle} color="red.500"/>
                No software installation
              </ListItem>
              <ListItem fontSize="2xl">
                <ListIcon as={MdRemoveCircle} color="red.500"/>
                Nothing is send to the server
              </ListItem>
              <ListItem fontSize="2xl">
                <ListIcon as={MdCheckCircle} color="green.500"/>
                Everything happens in your browser
              </ListItem>
            </List>
            {/* No software installation and no backend processing. Everything is done in your browser */}
          </Box>
        )}
        <Box width='100vh' hidden={streamSource == null}>
          <video ref={previewVideo} width='auto' height='auto'></video>
        </Box>
        <Box>
          <VStack spacing={4}>
            {streamSource && (
              <HStack spacing='5'>
                <Button
                  colorScheme='green'
                  size='lg'
                  disabled={recordingStarted}
                  onClick={() => startRecording()}
                >
                  Start record
                </Button>
                <Button
                  colorScheme='red'
                  size='lg'
                  disabled={!recordingStarted}
                  onClick={() => stopRecording()}
                >
                  Stop record
                </Button>
              </HStack>
            )}
            <HStack spacing='5'>
              <Button
                colorScheme='blue'
                size='md'
                isLoading={askingForSource}
                onClick={askForSource}
                loadingText='Choose a source'
                disabled={recordingStarted}
              >
                {streamSource ? 'Change Source' : 'Select Source'}
              </Button>
              {streamSource && (
                <Button
                  colorScheme='teal'
                  size='md'
                  onClick={cancelSource}
                  disabled={recordingStarted}
                >
                  Cancel
                </Button>
              )}
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </>
  );
}
