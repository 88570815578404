import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Grid,
  Spinner,
  createStandaloneToast
} from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import ColorModeSwitcher from './ColorModeSwitcher';
import Record from './Record';

const config = {
  useSystemColorMode: false,
  initialColorMode: "dark",
}

const customTheme = extendTheme({ config })

function App() {
  const toast = createStandaloneToast();
  const [checksDone, setChecksDone] = useState(false);

  useEffect(() => {
    if (navigator.mediaDevices) {
      setChecksDone(true);
    }
    else {
      toast({
        title: "Error",
        description: "Your browser is incopatible",
        status: "error",
        duration: null,
        position: 'top',
        isClosable: false,
      })
    }
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <Box textAlign="center">
        <ColorModeSwitcher />
        <Grid minH="100vh" p={3}>
          {checksDone ? <Record /> : <Spinner size="xl" />}
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
